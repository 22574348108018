import useSWR from 'swr'
import Api from '../../services/Api'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { dataToLineItems } from '../../utils/cart'
import { cartURL } from '../../ApiEndpoints'

const initialState = {
  isLoading: false,
  error: false,
  currency: {
    code: 'USD',
  },
  cartAmount: 0,
  lineItems: [],
  lineItemsLength: 0,
  redirectUrls: {},
  addingToCart: false,
}

export const useCart = () => {
  const { user } = useSelector((state) => state.auth)
  const cartId = user?.['cart_id']
  const [notifications, updateNotifications] = useState([])

  const addNotification = (text, type = 'notify') => {
    updateNotifications([...notifications, { text, type, id: Date.now() }])
  }

  const removeNotification = (id) => {
    updateNotifications(notifications.filter((ntfy) => ntfy.id !== id))
  }

  const { data, error, mutate } = useSWR(cartURL(cartId), getCart)

  const isLoading = !error && !data
  const lineItems = data?.['line_items'] ? data['line_items'] : 0
  const lineItemsLength = data?.['line_items'] ? data['line_items'].length : 0

  const { lowerInventoryLineItems, line_items } = dataToLineItems(lineItems)

  return {
    cart: data || initialState,
    isLoading,
    lineItems: line_items,
    lowerInventoryLineItems: lowerInventoryLineItems,
    lineItemsLength,
    mutate,
    notifications,
    removeNotification,
    addNotification,
  }
}

async function getCart(url) {
  const response = await Api.get(url)
  return response.data.data
}

export default useCart
